import React, { useState, useEffect } from "react";
import { Layout, Menu } from "antd";
import { BrowserRouter as Router, Link, useLocation } from "react-router-dom";
import { Image } from "antd";
import appconstant from "../../themes/appconstant";
import { FontAwesomeIcon, fadashcube } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUser,
  faNewspaper,
  faBell,
  faShoppingCart,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { Table, Breadcrumb } from "antd";
import Textfiled from "../../common";
import images from "../../themes/appImage";
import {EyeOutlined, CheckOutlined , CloseOutlined } from "@ant-design/icons"
import { useDispatch, useSelector } from "react-redux";
import { idVerificationAction, viewUserAction } from "../../redux/Action/Auth";
import { reduxConstant } from "../../constants/appconstant";

const { Header, Content, Footer, Sider } = Layout;

const Verifyuser = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const userDetail = useSelector((state) => state.userManagementReducer?.userDetail);

  const [profileStatus, setProfileStatus] = useState("");
  const [videoStatus, setVideoStatus] = useState("");
  const [idImageStatus, setIdImageStatus] = useState("");

  const handleStatusChange = (mediaId, statusType, status) => {
    if(statusType == "idImage"){
    const data = {
      mediaId: userDetail.verificationIdImage._id,
      status,
    };
    dispatch(idVerificationAction(data));

  }
  else if(statusType == "profile")
  {
    const data = {
      mediaId: userDetail.verificationProfile._id,
      status,
    };
    dispatch(idVerificationAction(data));


  }
   else if (statusType == "video"){

    const data = {
      mediaId: userDetail.verificationVideo._id,
      status,
    };
    dispatch(idVerificationAction(data));

   }
  
    if (statusType === "profile") setProfileStatus(status);
    if (statusType === "video") setVideoStatus(status);
    if (statusType === "idImage") setIdImageStatus(status);
  };

  useEffect(() => {
    document.title = "YezidiLink";
    window.scrollTo(0, 0);
    const data = { user: location?.state };
    dispatch(viewUserAction(data));
  }, [dispatch, location]);

  
  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">
            <FontAwesomeIcon icon={faHome} />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/usermanagement"> {appconstant.UserManagement} </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/userverification"> {appconstant.Userverfication} </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{appconstant.verificationdetail}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
            <h6 className="text-white text-capitalize ps-3">
              {appconstant.verificationdetail}
            </h6>
          </div>

          <div className="datacenter">
            <img src ={`${reduxConstant.IMAGE_URL}/${userDetail?.image}`} style={{ width: "100px", Height: "200px" }} />
          </div>
          <div className="wrapper_line">
            <div className="fields">
              <label>{appconstant.firstName}</label>
              <p>{userDetail?.firstName}</p>
            </div>
            <div className="fields">
              <label>{appconstant.lastname}</label>
            <p>{userDetail?.lastName}</p>
            </div>
            <div className="fields">
              <label>{appconstant.nickName}</label>
              <p>{userDetail?.nickName || "N/A"}</p>
            </div>
            <div className="fields">
              <label>{appconstant.gender}</label>
              <p>{userDetail?.gender || "N/A"}</p>
            </div>
            <div className="fields">
              <label>{appconstant.dateOfbirth}</label>
              <p>{userDetail?.dateOfBirth || "N/A"}</p>
            </div>
            <div className="fields">
              <label>{appconstant.EmailAddress}</label>
              <p>{userDetail?.email || "N/A"}</p>
            </div>
            <div className="fields">
              <label>{appconstant.phone}</label>
              <p>{userDetail?.phone || "N/A"}</p>
            </div>
            <div className="fields">
              <label>{appconstant.subscribe}</label>
              <p>{userDetail?.subscription || "N/A"}</p>
            </div>
            <div className="fields">
              <label>{appconstant.nextdata}</label>
              <p>{userDetail?.nextRenewalDate || "N/A"}</p>
            </div>
            <div className="fields">
              <label>{appconstant.status}</label>
              <p>{userDetail?.subscriptionStatus || "N/A"}</p>
            </div>
          </div>

          <div className="verifyId">
            {userDetail?.verificationProfile?.image?.length > 0 && (
              <div className="idcontent">
                <label>{appconstant.faceId}</label>
                <Image
                  width={200}
                  height={200}
                  src={`${reduxConstant.IMAGE_URL}/${userDetail?.verificationProfile?.image[0]}`}
                />
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                  <CheckOutlined
                    style={{ fontSize: "18px", color: profileStatus === "accept" ? "green" : "inherit" }}
                    onClick={() => handleStatusChange(location?.state, "profile", "accept")}
                  />
                  <CloseOutlined
                    style={{ fontSize: "18px", color: profileStatus === "reject" ? "red" : "inherit" }}
                    onClick={() => handleStatusChange(location?.state, "profile", "reject")}
                  />
                  {profileStatus && <p style={{ fontSize: "18px", color: profileStatus === "accept" ? "green" : "red" }}>{profileStatus === "accept" ? "Verified" : "Rejected"}</p>}
                </div>
              </div>
            )}
            {userDetail?.verificationVideo?.image?.length > 0 && (
              <div className="idcontent">
                <label>{appconstant.videoId}</label>
                <video width="200" height="200" controls>
                  <source src={`${reduxConstant.IMAGE_URL}/${userDetail?.verificationVideo?.image[0]}`} type="video/mp4" />
                </video>
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                  <CheckOutlined
                    style={{ fontSize: "18px", color: videoStatus === "accept" ? "green" : "inherit" }}
                    onClick={() => handleStatusChange(location?.state, "video", "accept")}
                  />
                  <CloseOutlined
                    style={{ fontSize: "18px", color: videoStatus === "reject" ? "red" : "inherit" }}
                    onClick={() => handleStatusChange(location?.state, "video", "reject")}
                  />
                  {videoStatus && <p style={{ fontSize: "18px", color: videoStatus === "accept" ? "green" : "red" }}>{videoStatus === "accept" ? "Verified" : "Rejected"}</p>}
                </div>
              </div>
            )}
            {userDetail?.verificationIdImage?.image?.length > 0 && (
              <div className="idcontent">
                <label>{appconstant.Idproof}</label>
                <Image
                  width={200}
                  height={200}
                  src={`${reduxConstant.IMAGE_URL}/${userDetail?.verificationIdImage?.image[0]}`}
                />
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                  <CheckOutlined
                    style={{ fontSize: "18px", color: idImageStatus === "accept" ? "green" : "inherit" }}
                    onClick={() => handleStatusChange(location?.state, "idImage", "accept")}
                  />
                  <CloseOutlined
                    style={{ fontSize: "18px", color: idImageStatus === "reject" ? "red" : "inherit" }}
                    onClick={() => handleStatusChange(location?.state, "idImage", "reject")}
                  />
                  {idImageStatus && <p style={{ fontSize: "18px", color: idImageStatus === "accept" ? "green" : "red" }}>{idImageStatus === "accept" ? "Verified" : "Rejected"}</p>}
                </div>
              </div>
            )}
          </div>



          <div className="userdataheading">Other Profle Details</div>

          <div className="data-item">
            <div className="left">
              <div className="inner">
                <p className="left-text">{appconstant.Age}</p>
                <p className="right-text">{userDetail?.age || "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.Children}</p>
                <p className="right-text">{userDetail?.children || "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.Height}</p>
                <p className="right-text">{userDetail?.height ? `${userDetail.height} cm` : "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.Interest}</p>
                <p className="right-text">{userDetail?.interest?.join(", ") || "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.ReligiousAfliation}</p>
                <p className="right-text">{userDetail?.religiousAffiliation || "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.Education}</p>
                <p className="right-text">{userDetail?.education || "N/A"}</p>
              </div>

              <div className="inner">
                <p className="left-text">{appconstant.ValuesandBeliefs}</p>
                <p className="right-text">{userDetail?.valueAndBeliefs?.join(", ") || "N/A"}</p>
              </div>

              <div className="inner">
                <p className="left-text">Facebook:</p>
                <p className="right-text">{userDetail?.facebook || "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">Instagram:</p>
                <p className="right-text">{userDetail?.instagram || "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">Tiktok:</p>
                <p className="right-text">{userDetail?.tiktok || "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.Traits}</p>
                <p className="right-text">{userDetail?.traits?.join(", ") || "N/A"}</p>
              </div>
              <div className="inner-other">
                <p className="left-text">{appconstant.Headlines}</p>
                <p className="right-text2" style={{ width: "70%" }}>{userDetail?.headlines || "N/A"}</p>
              </div>
            </div>

            <div className="right">
              <div className="inner">
                <p className="left-text">{appconstant.Relationship}</p>
                <p className="right-text">{userDetail?.relationshipStatus || "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.ZodiacSign}</p>
                <p className="right-text">{userDetail?.zodiac || "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.Weight}</p>
                <p className="right-text">{userDetail?.weight || "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.CurrentLocation}</p>
                <p className="right-text">{userDetail?.city || "N/A"}, {userDetail?.country || "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.Occupation}</p>
                <p className="right-text">{userDetail?.occupation || "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.RelationshipGoals}</p>
                <p className="right-text">{userDetail?.relationshipGoals?.join(", ") || "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.DealBreakers}</p>
                <p className="right-text">{userDetail?.dealBreakers?.join(", ") || "N/A"}</p>
              </div>
              <div className="inner-other">
                <p className="left-text">{appconstant.BioOrDescription}</p>
                <p className="right-text2" style={{ width: "150px" }}>{userDetail?.bio || "N/A"}</p>
              </div>
            </div>
    

       
          
            
          </div>
          {/* <div className="user-bio">


                    <div className="otherProfiledata">
                       <div className="userinfo">
                       <div><p>{appconstant.Age}</p>  <p className="userinfodata">{appconstant.AgeD}</p></div> 
                        <p>{appconstant.Relationship}</p>
                        <p>{appconstant.ZodiacSign}</p>
                        <p>{appconstant.Height}</p>
                        <p>{appconstant.Weight}</p>
                        <p>{appconstant.Interest}</p>
                        <p>{appconstant.CurrentLocation}</p>
                        <p>{appconstant.ReligiousAfliation}</p>
                        <p style={{paddingTop:'30px'}}>{appconstant.Headlines}</p>
                        </div> 
                      <div className="userinfodata">
                      <p>{appconstant.AgeD}</p>
                        <p>{appconstant.RelationshipD}</p>
                        <p>{appconstant.ZodiacSignD}</p>
                        <p>{appconstant.HeightD}</p>
                        <p>{appconstant.WeightD}</p>
                        <p>{appconstant.Interestd}</p>
                        <p>{appconstant.CurrentLocationD}</p>
                        <p>{appconstant.ReligiousAfliationD}</p>
                        <p style={{paddingTop:'30px'}}>{appconstant.HeadlinesD}</p>
                        </div>                 
                      </div>

                      <div className="otherProfiledata">
                       <div className="userinfo">
                        <p>{appconstant.ValuesandBeliefs}</p>
                        <p>{appconstant.DealBreakers}</p>
                        <p>{appconstant.BioOrDescription}</p>
                        <p>{appconstant. Occupation}</p>
                        <p>{appconstant.RelationshipGoals}</p>
                        <p>{appconstant. Children}</p>
                        <p>{appconstant.Traits}</p>
                        <p>{appconstant.BioOrDescription}</p>
                        </div> 
                      <div className="userinfodata">
                        
                      <p>{appconstant.ValuesandBeliefsD}</p>
                        <p>{appconstant.DealBreakersD}</p>
                        <p>{appconstant.BioOrDescriptionD}</p>
                        <p>{appconstant. OccupationD}</p>
                        <p>{appconstant.RelationshipGoalsD}</p>
                        <p>{appconstant. ChildrenD}</p>
                        <p>{appconstant.TraitsD}</p>
                        <p>{appconstant.BioOrDescriptionD}</p>
                      </div>
                      
                        </div>
                    </div> */}

          <div className="btns-app">
            <div className="btns-image">
              <Link className="btns-link" to="/usermanagement">
                <div className="btn-txt">{appconstant.submit}</div>{" "}
               
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Verifyuser;
