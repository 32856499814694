import React, { useState, useEffect } from "react";
import { Layout, Menu, Pagination, Select } from "antd";
import images from "../../themes/appImage";
import { DatePicker, Space } from "antd";

import { BrowserRouter as Router, Link } from "react-router-dom";
import HeaderAdmin from "../../layout/header";

import Sidebar from "../../layout/sidebar";
import { FontAwesomeIcon, fadashcube } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUser,
  faNewspaper,
  faBell,
  faShoppingCart,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { Table, Breadcrumb, Modal } from "antd";
import MobileSidebar from "../../layout/mobilesidebar";
import appconstant from "../../themes/appconstant";
import { typeList } from "antd/lib/message";
import { useDispatch, useSelector } from "react-redux";
import {
  userListAction,
  blockUserAction,
  getDeleteListAction,
} from "../../redux/Action/Auth";
const { Option } = Select;

const { RangePicker } = DatePicker;
const { Header, Content, Footer, Sider } = Layout;

const UserManagement = () => {
  const [blockState, setBlockState] = useState(null);
  const userListData = useSelector(
    (state) => state.userManagementReducer.userList
  );

  const paginationData = useSelector(
    (state) => state.userManagementReducer.pagination
  );
  const loading = useSelector((state) => state.userManagementReducer.isLoading);
  const blockSuccess = useSelector(
    (state) => state.userManagementReducer.isBlockSuccess
  );
  const deleteSuccess = useSelector(
    (state) => state.deleteManagementReducer.deleteSuccess
  );

  const [payload, setPayload] = useState({
    offset: 0,
    limit: 10,
    sort: null,
    order: null,
    search: "",
    block : blockState
  });
  const [searchValue, setSearchValue] = useState("");

  const dispatch = useDispatch();
  const blockModal = (currentUser) => {
    console.log(currentUser, "fkfosfkjo");
    Modal.confirm({
      title: currentUser.isBlock ? "Unblock" : "Block",
      content: currentUser.isBlock
        ? "Are you sure, you want to unblock this user?"
        : "Are you sure, you want to block this user?",
      okText: "Yes",
      centered: true,
      cancelText: "No",
      onOk() {
        const blockData = {
          user: currentUser._id,
        };
        dispatch(blockUserAction(blockData));
      },
      onCancel() {},
      maskClosable: true,
      className: "new-button",
    });
  };

  const deleteModel = (id) => {
    Modal.confirm({
      title: "Delete",
      content: (
        <div className="coins-input ">
          <p style={{ marginTop: "20px" }}>
            {" "}
            Are you sure, you want to delete this user?
          </p>
        </div>
      ),
      okText: "Yes ",
      centered: true,
      cancelText: "No ",
      onOk() {
        const data = {
          user: id,
        };

        dispatch(getDeleteListAction(data));
        dispatch(
          userListAction({ ...payload, search: searchValue, offset: 0 })
        );
      },
      onCancel() {},
      maskClosable: true,
      className: "new-button",
    });
  };
  useEffect(() => {
    dispatch(userListAction({ ...payload, search: searchValue, offset: 0 }));
  }, [searchValue]);

  useEffect(() => {
    dispatch(userListAction({ ...payload, block: blockState }));
  }, [blockState]);

  useEffect(() => {
    if (blockSuccess == true || deleteSuccess == true) {
      dispatch(
        dispatch(userListAction({ ...payload, search: searchValue, offset: 0 }))
      );
    }
  }, [blockSuccess, deleteSuccess]);

  const handleSearch = (e) => {
    const serachData = e?.target?.value;
    if (!serachData?.startsWith(" ")) setSearchValue(serachData);
  };


  const handleSelectChange = (value) => {
    // Directly use the value to set the blockState
    let newBlockState;
    if (value === "block") {
      newBlockState = true;
    } else if (value === "unblock") {
      newBlockState = false;
    } else {
      newBlockState = null; 
    }

    setBlockState(newBlockState);
    // Use newBlockState here
  };


  const handleChange = (col, page) => {
    const tempPayload = { ...payload };

    if (col && col.columnKey) {
      tempPayload["sort"] = col?.columnKey || payload?.sort;
      tempPayload["order"] =
        col?.order === "ascend"
          ? 1
          : col?.order === "descend"
          ? -1
          : payload?.order;
    } else if (page) {
      tempPayload["offset"] = (page - 1) * 10;
    }
    setPayload(tempPayload);
    dispatch(userListAction({ ...tempPayload }));
  };

  const dataSource = userListData?.map((e) => {
    console.log(e, "asdjkhas");
    return {
      _id: e?._id,
      email: e?.email,
      firstName: e?.firstName,
      lastName: e?.lastName,
      phone: e?.phone,
      countryCode: e?.countryCode,
      Gender: e?.gender,
      isBlock: e?.isBlock,
    };
  });

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "srno",
      key: "srno",
      sorter: false,
      render: (value, item, index) => payload.offset + (index + 1),
    },
    {
      title: "Full Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (title, data, index) => (
        <>
          {data?.firstName || data?.lastName
            ? `${data?.firstName || ""} ${data?.lastName || ""}`
            : "N/A"}
        </>
      ),
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      sorter: true,
      render: (title, data, index) => data?.email || "N/A",
    },
    {
      title: "Gender",
      dataIndex: "Gender",
      key: "gender",
      sorter: true,
      render: (title, data, index) => data?.Gender || "N/A",
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
      sorter: true,
      render: (title, data, index) => (
        <>{data?.countryCode ? `${data?.countryCode} ${data?.phone}` : "N/A"}</>
      ),
    },
    {
      title: "Status",
      dataIndex: "isBlock",
      key: "isBlock",
      sorter: true,
      render: (title, data, index) => (
        <>{data?.isBlock ? "Block" : "UnBlock" }</>
      ),
    },
    {
      title: "Actions",
      dataIndex: "Posted",
      key: "Posted",
      render: (title, data, index) => (
        <div>
          <Link to="/usesrdetail" state={data?._id}>
            <button className="view-btn" type="submit">
              {appconstant.view}
            </button>
          </Link>
          <Link to="/edituserdetail" state={data?._id}>
            <button className="edit-btn" type="submit">
              {appconstant.edit}
            </button>
          </Link>
          <button
            className="block-btn"
            type="submit"
            onClick={() => blockModal(data)}
          >
            {data?.isBlock ? appconstant.unBlock : appconstant.block}
          </button>
          <button
            className="add-coins"
            type="submit"
            onClick={() => deleteModel(data?._id)}
          >
            {appconstant.delete}
          </button>
        </div>
      ),
    },
  ];

  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };


  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [isModalVisibleBlock, setIsModalVisibleBlock] = useState(false);
  const showModalBlock = () => {
    setIsModalVisibleBlock(true);
  };

  const handleOkBlock = () => {
    setIsModalVisibleBlock(false);
  };

  const handleCancelBlock = () => {
    setIsModalVisibleBlock(false);
  };

  const [isVisible, setVisible] = useState(false);
  const handlewClick = () => {
    setVisible(!isVisible);
  };
  useEffect(() => {
    document.title = "YezidiLink";
  }, []);

  return (
    <div>
      {/* <BreadcrumbFn
                path={["dashboard", "usermanagement"]}
                pathName={[
                    <FontAwesomeIcon icon={faHome} />,
                    appconstant.UserManagemen,
                    
                ]}

            /> */}

      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">
            <FontAwesomeIcon icon={faHome} />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{appconstant.UserManagement}</Breadcrumb.Item>
      </Breadcrumb>

      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
            <h6 class="text-white text-capitalize ps-3">
              {appconstant.UserManagement}
            </h6>
            <div className="search-button">
            <Select
        defaultValue="all" 
        style={{ width: 120 }}
        onChange={handleSelectChange} // Handle change event
      >
        <Option value="all">All</Option>
        <Option value="block">Block</Option>
        <Option value="unblock">Unblock</Option>
      </Select>
              <Link to="/userverification">
                <button className="edit-btn" type="submit">
                  {appconstant.verification}
                </button>
              </Link>
              <input
                type="text"
                onChange={handleSearch}
                className="iocn-search  search-bar"
                placeholder="Search"
                value={searchValue}
              />
            </div>
          </div>

          <Table
            className="unser-manage-table"
            dataSource={dataSource}
            columns={columns}
            showSorterTooltip={false}
            loading={loading}
            pagination={false}
            onChange={(x, y, col) => handleChange(col)}
          />
          <Pagination
            showTitle={false}
            className="pagination-wrapper"
            total={paginationData?.totalCount}
            current={paginationData?.currentPage}
            onChange={(page) => handleChange(null, page)}
          />
        </div>
      </div>
    </div>
  );
};
export default UserManagement;
