import React from "react";
import { Layout, Menu } from 'antd';
import { useLocation } from "react-router"

import {
  BrowserRouter as Router,
  Link,
  useNavigate
} from "react-router-dom";
import images from "../themes/appImage";
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome'
import { faHome, faUnlock, faUser, faTrophy, faBell, faSignOutAlt, faAngleLeft, faAngleRight, faPhone, faWrench, } from "@fortawesome/free-solid-svg-icons";

import { Button, Tooltip, ConfigProvider } from 'antd';
const text = <span>prompt text</span>;
const { Header, Content, Footer, Sider } = Layout;

const items = [
  {
    label: (
      <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
        1st menu item
      </a>
    ),
    key: '0',
  },
  {
    label: (
      <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
        2nd menu item
      </a>
    ),
    key: '1',
  },

];

const Sidebar = (props) => {
  let navigate = useNavigate();
  let location = useLocation();


  const onMenuClick = (e) => {
    navigate(e.key)
    // history.push(e.key);
  };


  let activeKey = ""
  switch (location.pathname) {
    case "/dashboard":
      activeKey = "/dashboard"
      break;

      case "/usermanagement":
        activeKey = "/usermanagement"
        break;

        case "/edituserdetail":
          activeKey = "/usermanagement"
          break;

    case "/globalmanagement":
      activeKey = "/globalmanagement"
      break;

      case "/createmessage":
        activeKey = "/globalmanagement"
        break;
  
      
      case "/reportmanagement":
        activeKey = "/reportmanagement"
        break;

        case "/reportdetails":
        activeKey = "/reportmanagement"
        break;

      case "/deleteuser":
        activeKey = "/deleteuser"
        break;

        
        case "/deleteuserdetails":
        activeKey = "/deleteuser"
        break;

    case "/usesrdetail":
      activeKey = "/usermanagement"
      break;

      case "/userverification":
        activeKey = "/usermanagement"
        break;

        case "/verifyuser":
          activeKey = "/usermanagement"
          break;

          case "/contact":
            activeKey = "/contact"
            break;

            case "/contactdetails":
              activeKey = "/contact"
              break;
  
            case "/settings":
              activeKey = "/settings"
              break;

              case "/changepassword":
                activeKey = "/settings"
                break;

            
            
  }

  return (
    <Sider
      className={props.isVisible ? 'sider_left new closedd ' : 'sider_left new '}
      collapsedWidth="0"
      width={props.isVisible ? '108' : '252'}
      style={{ overflow: 'hidden', position: 'fixed' }}

    >
      <div className="logo">
        <Link to="/dashboard">
          <img src={images.logo1} />
        </Link>

      </div>
      <div className="admin-text">
        <h2>Admin Panel</h2>
      </div>



      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={['1']}
        defaultOpenKeys={[activeKey]}
        selectedKeys={[activeKey]}
        onClick={(e) => onMenuClick(e)}>


        <Menu.Item key="/dashboard" className="side-btn">
          {props.isVisible && (
            <Tooltip placement="right" title="Dashboard">
              <Link to="">
                <FontAwesomeIcon icon={faHome} />
                <span className="line_dash">Dashboard</span>
              </Link>
            </Tooltip>
          )}
          {!props.isVisible && (
            <Link to="">
              <FontAwesomeIcon icon={faHome} />
              <span className="line_dash">Dashboard</span>
            </Link>
          )}
        </Menu.Item>




        <Menu.Item key="/usermanagement" className="side-btn">
          {props.isVisible && (
            <Tooltip placement="right" title="User Management">
              <Link to="">
                <FontAwesomeIcon icon={faUser} />
                <span className="line_dash">User Management</span>
              </Link>
            </Tooltip>
          )}

          {!props.isVisible && (
            <Link to="">
              <FontAwesomeIcon icon={faUser} />
              <span className="line_dash">User Management</span>
            </Link>
          )}

        </Menu.Item>


        <Menu.Item key="/globalmanagement" className="side-btn">
          {props.isVisible && (
            <Tooltip placement="right" title="Globlal Announcement">
              <Link to="">
                <FontAwesomeIcon icon={faTrophy} />
                <span className="line_dash">Globlal Announcement</span>
              </Link>
            </Tooltip>
          )}

          {!props.isVisible && (
            <Link to="">
              <FontAwesomeIcon icon={faTrophy} />
              <span className="line_dash">Global Announcement</span>
            </Link>


          )}


        </Menu.Item>

        <Menu.Item key="/reportmanagement" className="side-btn">
          {props.isVisible && (
            <Tooltip placement="right" title="Report Management">
              <Link to="">
                <FontAwesomeIcon icon={faBell} />
                <span className="line_dash">Report Management
                </span>
              </Link>
            </Tooltip>
          )}
          {!props.isVisible && (
            <Link to="">
              <FontAwesomeIcon icon={faBell} />
              <span className="line_dash">Report Management
              </span>
            </Link>
          )}
        </Menu.Item>



        <Menu.Item key="/deleteuser">
          {props.isVisible && (
            <Tooltip placement="right" title="Deleted User Management" className="side-btn">
              <Link to="">
                <FontAwesomeIcon icon={faUnlock} />
                <span className="line_dash">Deleted User Management</span>
              </Link>
            </Tooltip>
          )}
          {!props.isVisible && (
            <Link to="">
              <FontAwesomeIcon icon={faUnlock} />
              <span className="line_dash">Deleted User Management</span>
            </Link>
          )}
          
        </Menu.Item>


        

        <Menu.Item key="/contact" className="side-btn">
          {props.isVisible && (
            <Tooltip placement="right" title="Contact Us">
              <Link to="">
                <FontAwesomeIcon icon={faPhone} />
                <span className="line_dash">Contact Us </span>
              </Link>
            </Tooltip>
          )}
          {!props.isVisible && (
            <Link to="">
              <FontAwesomeIcon icon={faPhone} />
              <span className="line_dash">Contact Us</span>
            </Link>
          )}
          
        </Menu.Item>


          <Menu.Item key="/settings" className="side-btn">
          {props.isVisible && (
            <Tooltip placement="right" title="Settings">
              <Link to="">
                <FontAwesomeIcon icon={faWrench} />
                <span className="line_dash"> Settings</span>
              </Link>
            </Tooltip>
          )}

          {!props.isVisible && (
            <Link to="">
              <FontAwesomeIcon icon={faWrench} />
              <span className="line_dash"> Settings</span>
            </Link>
          )}

        </Menu.Item>
      


      </Menu>
       
  

      <div className="angle-btn">
        <button type="submit" onClick={props.handlewClick} className=" btn ">
          <FontAwesomeIcon className="icon-angle-left" icon={props.isVisible ? faAngleRight : faAngleLeft} /></button>
      </div>
    </Sider>

  )
}
export default Sidebar;